// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

// Grayscale
$white:                 #fff !default;
$gray-100:              #f6f9fc !default;
$gray-200:              #f3f5f9 !default;
$gray-300:              #e3e9ef !default;
$gray-400:              #dae1e7 !default;
$gray-500:              #aeb4be !default;
$gray-600:              #7d879c !default;
$gray-700:            	#4b566b !default;
$gray-800:              #373f50 !default;
$gray-900:              #2b3445 !default;
$black:                 #000 !default;

// Paletton
$brand: #228C36 !default;
$complementary: #8c227a !default;
$triadic1: #34228c !default;
$triadic2: #8c3422 !default;

$brand-100: #68C077 !default;
$brand-200: #3DA24F !default;
$brand-300: $brand !default;
$brand-400: #0E721F !default;
$brand-500: #00510E !default;

$teal-100: #54919A !default;
$teal-200: #337882 !default;
$teal-300: #1D6670 !default;
$teal-400: #0D515B !default;
$teal-500: #013941 !default;

$orange-100: #FBBF88 !default;
$orange-200: #D48F50 !default;
$orange-300: #B76F2C !default;
$orange-400: #955113 !default;
$orange-500: #6A3300 !default;

$red-100: #FB9088 !default;
$red-200: #D45A50 !default;
$red-300: #B7362C !default;
$red-400: #951C13 !default;
$red-500: #6A0800 !default;

$pink-100: #CF70A0 !default;
$pink-200: #AE4279 !default;
$pink-300: #96255E !default;
$pink-400: #7A0F45 !default;
$pink-500: #57002C !default;

// Brand colors
$primary:               #228C36 !default;
$accent:                $pink-300 !default;
$secondary:             $gray-200 !default;
$info:		            $teal-200 !default;
$success:               #68C077 !default;
$warning:               #B76F2C !default;
$danger:                #B7362C !default;
$light:                 $white !default;
$dark:                  $gray-800 !default;
