.navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.navbar-brand span {
    font-size: 2rem;
}

.page-title, .page-title-overlap, .under-nav {
    margin-top: 80px;
}



.help-accordion .card {
    border: 0;
    border-radius: 0;
}

.help-accordion .card-header {
    position: relative;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    border-bottom: 0;
    border-top: 1px solid rgba(0,0,0,0.085);
    cursor: pointer;
}
.help-accordion .card-header:hover {
    background-color: #a7d1af;
}

.help-accordion .card-body {
    border-left: 10px solid rgba(0,0,0,0.085);
    padding-right: 3rem;
}

.help-accordion .accordion-indicator.off {
    transform: rotate(-180deg);
}
.help-accordion .accordion-indicator.on {
    transform: rotate(0deg);
}

.cms-page .widget-list-link {
    font-size: 1rem;
}

.links-accordion {
    border: 1px solid rgba(0,0,0,0.085);
    border-radius: 0.5rem;
}

.links-accordion > div > .card-header {
    position: relative;
    padding: 1rem 3rem !important;
    cursor: pointer;
}

.links-accordion .card-header:hover {
    background-color: #f3f5f9;
    border-radius: 0;
}

.links-accordion .collapse-body {
    background-color: #c1dfc7;
    padding: 1rem 0;
}

.links-accordion .collapse-body a {
    display: block;
    padding: 0.5rem 3rem;
    color: #4b566b;
}

.links-accordion .collapse-body a:hover {
    background-color: #a7d1af;
    color: #4b566b;
}

.links-accordion .accordion-indicator.off {
    transform: rotate(-180deg);
    background-color: transparent;
}
.links-accordion .accordion-indicator.on {
    transform: rotate(0deg);
    background-color: transparent;
}

.links a.active {
    font-weight: 500;
}

.links h6 a {
    color: #373f50;
}

.links h6 a:hover {
    text-decoration: underline;
}

.tab-content {
    margin-top: 2rem;
}